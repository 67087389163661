var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_account_receivable_receipt") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.receiptArForm,
                rules: _vm.formRules,
                "label-col": { span: 8 },
                "wrapper-col": { span: 12 },
                layout: "vertical"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchWarehouseId"
                          }
                        },
                        [
                          _c("branch-select", {
                            attrs: { disabled: _vm.disable.form },
                            model: {
                              value: _vm.receiptArForm.branchWarehouseId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.receiptArForm,
                                  "branchWarehouseId",
                                  $$v
                                )
                              },
                              expression: "receiptArForm.branchWarehouseId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_transaction_type"),
                            prop: "receiptType"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "lbl_" +
                                    _vm.receiptArForm.receiptType.toLowerCase()
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_account_recievable_number"),
                            prop: "arReceiptNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.arReceiptDetail.documentNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.arReceiptDetail,
                                  "documentNumber",
                                  $$v
                                )
                              },
                              expression: "arReceiptDetail.documentNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_type"),
                            prop: "invoiceType"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { disabled: _vm.disable.form },
                              on: { change: _vm.handleChangeInvoiceType },
                              model: {
                                value: _vm.receiptArForm.invoiceType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.receiptArForm,
                                    "invoiceType",
                                    $$v
                                  )
                                },
                                expression: "receiptArForm.invoiceType"
                              }
                            },
                            _vm._l(_vm.dataInvoiceType, function(item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(_vm._s(item.value))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "currency",
                            label: _vm.$t("lbl_currency")
                          }
                        },
                        [
                          _c("currency-select", {
                            attrs: { disabled: _vm.disable.form },
                            on: { change: _vm.handleCurrencyChange },
                            model: {
                              value: _vm.receiptArForm.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "currency", $$v)
                              },
                              expression: "receiptArForm.currency"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.showFields.rate
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                prop: "currencyRate",
                                label: _vm.$t("lbl_rate")
                              }
                            },
                            [
                              _c("a-input-number", {
                                staticClass: "w-100",
                                attrs: {
                                  disabled: _vm.disable.form,
                                  "allow-clear": "",
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  min: 1,
                                  precision: _vm.storeBaseDecimalPlace
                                },
                                model: {
                                  value: _vm.receiptArForm.currencyRate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.receiptArForm,
                                      "currencyRate",
                                      $$v
                                    )
                                  },
                                  expression: "receiptArForm.currencyRate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "receiptAmount",
                            label: _vm.$t("lbl_receipt_amount")
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              disabled: !_vm.disable.update
                                ? false
                                : _vm.disable.form,
                              "allow-clear": "",
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace
                            },
                            model: {
                              value: _vm.receiptArForm.receiptAmount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.receiptArForm,
                                  "receiptAmount",
                                  $$v
                                )
                              },
                              expression: "receiptArForm.receiptAmount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receipt_date"),
                            prop: "receiptDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: _vm.$t(
                                "lbl_select_date_placeholder"
                              ),
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: _vm.disable.form
                            },
                            model: {
                              value: _vm.receiptArForm.receiptDate,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "receiptDate", $$v)
                              },
                              expression: "receiptArForm.receiptDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receipt_time"),
                            prop: "receiptTime"
                          }
                        },
                        [
                          _c("a-time-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.TIME_FORMAT_HH_mm,
                              disabled: _vm.disable.editableField
                            },
                            model: {
                              value: _vm.receiptArForm.receiptTime,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "receiptTime", $$v)
                              },
                              expression: "receiptArForm.receiptTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "customerId",
                            label: _vm.$t("lbl_customer_name")
                          }
                        },
                        [
                          _c("customer-select", {
                            attrs: {
                              disabled: _vm.disable.editableField,
                              customerName: _vm.customerName,
                              customerId: _vm.customerId
                            },
                            on: {
                              change: _vm.handleChangeCustomer,
                              changeCustomer: _vm.initCustomerAddress
                            },
                            model: {
                              value: _vm.receiptArForm.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "customerId", $$v)
                              },
                              expression: "receiptArForm.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "customerBillToAddress",
                            label: _vm.$t("lbl_customer_bill_address")
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                disabled: _vm.disable.editableField,
                                allowClear: true,
                                loading: _vm.customerAddressOption.fetching
                              },
                              model: {
                                value: _vm.receiptArForm.customerBillToAddress,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.receiptArForm,
                                    "customerBillToAddress",
                                    $$v
                                  )
                                },
                                expression:
                                  "receiptArForm.customerBillToAddress"
                              }
                            },
                            _vm._l(_vm.customerAddressOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receipt_method"),
                            prop: "receiptMethod"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                disabled: !_vm.disable.update
                                  ? false
                                  : _vm.disable.form
                              },
                              on: { change: _vm.handleReceiptMethodChange },
                              model: {
                                value: _vm.receiptArForm.receiptMethod,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.receiptArForm,
                                    "receiptMethod",
                                    $$v
                                  )
                                },
                                expression: "receiptArForm.receiptMethod"
                              }
                            },
                            _vm._l(_vm.receiptMethodsOptions, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showFields.cheque
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_cheque_date"),
                                prop: "chequeDate"
                              }
                            },
                            [
                              _c("a-date-picker", {
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lbl_select_date_placeholder"
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: !_vm.disable.update
                                    ? false
                                    : _vm.disable.form,
                                  "disabled-date": _vm.disabledDate
                                },
                                model: {
                                  value: _vm.receiptArForm.chequeDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.receiptArForm,
                                      "chequeDate",
                                      $$v
                                    )
                                  },
                                  expression: "receiptArForm.chequeDate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showFields.cheque
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_cheque_number"),
                                prop: "chequeNumber"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  disabled: !_vm.disable.update
                                    ? false
                                    : _vm.disable.form
                                },
                                model: {
                                  value: _vm.receiptArForm.chequeNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.receiptArForm,
                                      "chequeNumber",
                                      $$v
                                    )
                                  },
                                  expression: "receiptArForm.chequeNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showFields.cheque
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_bank_customer_name"),
                                prop: "customerBankName"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  disabled: !_vm.disable.update
                                    ? false
                                    : _vm.disable.form
                                },
                                model: {
                                  value: _vm.receiptArForm.customerBankName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.receiptArForm,
                                      "customerBankName",
                                      $$v
                                    )
                                  },
                                  expression: "receiptArForm.customerBankName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            prop: "bankAccountId",
                            label: _vm.$t("lbl_bank_account_name")
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                disabled: !_vm.disable.update
                                  ? false
                                  : _vm.disable.form,
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.bankAccountOption.fetching
                              },
                              on: { search: _vm.searchBankAccount },
                              model: {
                                value: _vm.receiptArForm.bankAccountId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.receiptArForm,
                                    "bankAccountId",
                                    $$v
                                  )
                                },
                                expression: "receiptArForm.bankAccountId"
                              }
                            },
                            _vm._l(_vm.bankAccountOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.id } },
                                [
                                  _vm._v(
                                    _vm._s(item.bankName) +
                                      " - " +
                                      _vm._s(item.bankAccNumber)
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              disabled: !_vm.disable.update
                                ? false
                                : _vm.disable.form,
                              placeholder: _vm.$t("lbl_description"),
                              rows: 4
                            },
                            model: {
                              value: _vm.receiptArForm.description,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "description", $$v)
                              },
                              expression: "receiptArForm.description"
                            }
                          }),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "lbl_characters-x",
                                  _vm.receiptArForm.description.length,
                                  { data: _vm.receiptArForm.description.length }
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.arReceiptDetail.journals &&
                      _vm.arReceiptDetail.journals.length
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_journal_number"),
                                prop: "journalNumber"
                              }
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "list-unstyled" },
                                _vm._l(_vm.arReceiptDetail.journals, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "mb-2" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "generaljournal.detail",
                                              params: { id: item.id }
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            { attrs: { type: "link" } },
                                            [_vm._v(_vm._s(item.number))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_status"), prop: "status" }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.receiptArForm.status,
                              callback: function($$v) {
                                _vm.$set(_vm.receiptArForm, "status", $$v)
                              },
                              expression: "receiptArForm.status"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_attachment"),
                            prop: "attachment"
                          }
                        },
                        [
                          !_vm.disable.editableField
                            ? _c(
                                "a-upload",
                                {
                                  attrs: {
                                    name: "file",
                                    action: "" + _vm.Api.UploadInvoice,
                                    headers: _vm.headers,
                                    multiple: false,
                                    accept: _vm.ALLOWED_FILE_TYPES,
                                    beforeUpload: _vm.beforeUpload,
                                    "file-list": _vm.fileList,
                                    remove: _vm.removeFile
                                  },
                                  on: { change: _vm.handleAttachmentChange }
                                },
                                [
                                  _c(
                                    "a-button",
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "upload" }
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.id
                            ? _c(
                                "div",
                                {
                                  class: _vm.disable.editableField ? "" : "mt-3"
                                },
                                [
                                  _vm.arReceiptDetail.fileAttachment &&
                                  _vm.arReceiptDetail.fileAttachment.length > 0
                                    ? _c(
                                        "ol",
                                        _vm._l(
                                          _vm.arReceiptDetail.fileAttachment,
                                          function(item, index) {
                                            return _c("li", { key: index }, [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: item,
                                                    target: "_blank",
                                                    rel: "noopener noreferrer"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Attachment " +
                                                      _vm._s(index + 1) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _c("span", [_vm._v("-")])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled:
                          _vm.disable.editableField || _vm.disable.addRowBtn,
                        type: "primary"
                      },
                      on: { click: _vm.handleAddRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      dataSource: _vm.invoiceSource,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(700px + 50%)" },
                      paginationcustom: false,
                      defaultPagination: {
                        showTotal: function(total) {
                          return _vm.$t("lbl_total_items", { total: total })
                        }
                      },
                      handleSelect: _vm.handleSelect,
                      handleInput: _vm.handleInput,
                      handleInputNumber: _vm.handleInputNumber,
                      handleSearchSelectTable: _vm.handleSearchSelectTable
                    },
                    on: {
                      "on-delete": _vm.reponseDeleteRow,
                      "on-addDataResponsive": _vm.handleAddData
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "custom-footer",
                        fn: function() {
                          return [
                            _c(
                              "a-row",
                              {
                                staticClass: "flex-column align-items-md-end",
                                attrs: { type: "flex" }
                              },
                              [
                                _c("a-col", { attrs: { span: 10 } }, [
                                  _c(
                                    "table",
                                    {
                                      staticClass: "footer-table",
                                      attrs: {
                                        "aria-describedby": "footer table"
                                      }
                                    },
                                    [
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl_total_invoice_amount"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total.invoice
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("lbl_total_paid_amount")
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total.paid
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl_total_write_off_account"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total.writeOff
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl_total_additional_amount"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total.additionalAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "lbl_total_invoice_outstanding"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total.outstandingInvoice
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.statusDocument.approved || _vm.statusDocument.new
                        ? _c(
                            "a-button",
                            {
                              attrs: { loading: _vm.loadingIndicator.print },
                              on: { click: _vm.handlePrint }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isCanVoid && _vm.$can("update", "invoice-receipt")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.onSubmit(_vm.STATUS.VOID)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loadingIndicator.void
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_void")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.statusDocument.submitted &&
                      _vm.$can("update", "invoice-receipt")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loadingIndicator.approved
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit(_vm.STATUS.APPROVED)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e(),
                      _vm.statusDocument.submitted &&
                      _vm.$can("update", "invoice-receipt")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.onSubmit(_vm.STATUS.CANCELED)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loadingIndicator.canceled
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.statusDocument.submitted &&
                      _vm.$can("update", "invoice-receipt")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loadingIndicator.updated
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit(_vm.STATUS.UPDATED)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                          )
                        : _vm._e(),
                      !_vm.id &&
                      (_vm.$can("update", "invoice-receipt") ||
                        _vm.$can("create", "invoice-receipt"))
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                disabled: _vm.disable.form,
                                type: "primary"
                              },
                              on: { click: _vm.handleSubmit }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.id &&
                      !_vm.disable.editableField &&
                      (_vm.$can("update", "invoice-receipt") ||
                        _vm.$can("create", "invoice-receipt"))
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleApply }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_apply")) + " ")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 800,
            title: _vm.$t(_vm.titleModal),
            showmodal: _vm.visible,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled:
                          _vm.receiptArForm.status === _vm.STATUS.NEW ||
                          _vm.receiptArForm.status === _vm.STATUS.SUBMITTED
                            ? false
                            : true,
                        type: "primary"
                      },
                      on: { click: _vm.handleAddRowModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled:
                          _vm.receiptArForm.status === _vm.STATUS.NEW ||
                          _vm.receiptArForm.status === _vm.STATUS.SUBMITTED
                            ? false
                            : true,
                        type: "danger"
                      },
                      on: { click: _vm.handleDeleteRowModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "1rem" } },
                [
                  _c("TableCustom", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.titleModal === "lbl_invoice_credit_memo",
                        expression: "titleModal === 'lbl_invoice_credit_memo'"
                      }
                    ],
                    attrs: {
                      idtable: "table2",
                      dataSource: _vm.dataSourceModal,
                      columns: _vm.columnsTableModal,
                      scroll: { x: "calc(100px + 50%)" },
                      paginationcustom: false,
                      defaultPagination: true,
                      onSelectChange: _vm.onSelectChange,
                      selectedRowKeys: _vm.selectedRowKeysModal,
                      handleSelect: _vm.handleSelectModal,
                      handleSearchSelectTable: _vm.handleSearchSelectTableModal,
                      handleInput: _vm.handleInputModal
                    }
                  }),
                  _c("TableCustom", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.titleModal === "lbl_write_off_amount" ||
                          _vm.titleModal === "lbl_additional_amount",
                        expression:
                          "\n            titleModal === 'lbl_write_off_amount' ||\n            titleModal === 'lbl_additional_amount'\n          "
                      }
                    ],
                    attrs: {
                      idtable: "table2",
                      dataSource: _vm.dataSourceModalWriteOfAndAdditional,
                      columns: _vm.columnsTableModalWriteOfAndAdditionalAmount,
                      scroll: { x: "calc(100px + 50%)" },
                      paginationcustom: false,
                      defaultPagination: true,
                      onSelectChange: _vm.onSelectChange,
                      selectedRowKeys: _vm.selectedRowKeysModal,
                      handleSelect: _vm.handleSelectModal,
                      handleSearchSelectTable: _vm.handleSearchSelectTableModal,
                      handleInput: _vm.handleInputModal
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.receiptArForm.status === _vm.STATUS.NEW ||
                      _vm.receiptArForm.status === _vm.STATUS.SUBMITTED
                        ? false
                        : true
                  },
                  on: { click: _vm.saveDataModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }