







































































































































































































































































































































































































































































































































































































import { removeDuplicate } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { useDate } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { Api } from "@/models/class/api.class";
import { OptionModel } from "@/models/constant/interface/common.interface";
import {
  DEFAULT_DATE_FORMAT,
  TIME_FORMAT_HH_mm,
} from "@/models/constants/date.constant";
import {
  ALLOWED_EXCEL_FILE,
  ALLOWED_FILE_SIZE,
  ALLOWED_IMAGE_FILE,
} from "@/models/constants/upload.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  IArDataPayload,
  IArDataResponse,
  IReceiptLine,
  LineAmountsAR,
} from "@/models/interface/AccountReceivables.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseCompanyBank } from "@/models/interface/master.interface";
import { IAuthorities } from "@/models/interfaces/auth.interface";
import { arService } from "@/services/ar.service";
import { invoiceServices } from "@/services/invoice.service";
import { invoicePrepayment } from "@/services/invoicePrepayment";
import LocalStorageService from "@/services/LocalStorage.service";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  formatterNumber,
  isDateAfterMaxDate,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Decimal } from "decimal.js-light";
import moment, { Moment } from "moment";
import printJs from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapState } from "vuex";
import { RECEIPT_TYPE } from "./ReceiptTypeSelect.vue";

enum RECEIPT_METHOD {
  CASH = "CASH",
  BANK_TRANSFER = "BANK_TRANSFER",
  CHEQUE = "CHEQUE",
}

enum STATUS {
  NEW = "New",
  SUBMITTED = "Submitted",
  POSTED = "Posted",
  VOID = "Void",
  APPROVED = "Approved",
  UPDATED = "Updated",
  CANCELED = "Canceled",
}

enum InvoiceType {
  INVOICE_AR = "Invoice AR",
  CREDIT_MEMO = "Credit Memo",
  PREPAYMENT = "Prepayment",
}

interface CustomDataSourceModal {
  key: number;
  appliedAmount: number | string;
  description: string;
  invoicePrepaymentId: string;
  invoicePrepaymentNo: string;
  id: string;
  createdDate?: string;
  modifiedDate?: string;
  invoicePrepaymentDate: string;
}

type FormValues = {
  receiptTime: Moment;
} & IArDataPayload;

@Component({
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  watch: {
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    mode: {
      immediate: true,
      deep: true,
      handler() {
        this.setBaseCurrencyCreate();
      },
    },
    currencyFrom: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const params: RequestQueryParamsModel = {
          search: `fromCurrency.currencyCode~${newVal}_AND_toCurrency.currencyCode~${this.currencyTo}`,
        };
        settingsServices.listOfCurrency(params, "").then(response => {
          if (response.data[0]) {
            this.receiptArForm.currencyRate = response.data[0].rate;
          } else if (newVal === this.currencyTo) {
            this.receiptArForm.currencyRate = 1;
          } else {
            this.receiptArForm.currencyRate = 0;
          }
        });
        this.getBankList();
      },
    },
    invoiceSource: {
      immediate: true,
      deep: true,
      handler() {
        let longestDate = "";
        this.invoiceSource.forEach((dataForeach, index) => {
          // jika index 0 atau invoice date kurang atau lebih lama dari longestdate saat ini
          if (
            index === 0 ||
            moment(dataForeach.invoiceDate).isBefore(longestDate)
          )
            longestDate = dataForeach.invoiceDate;
        });
        this.longestDate = moment(longestDate).format(DEFAULT_DATE_FORMAT);
      },
    },
  },
})
export default class ReceiptArForm extends Mixins(MNotification) {
  TIME_FORMAT_HH_mm = TIME_FORMAT_HH_mm;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  Api = Api;
  STATUS = STATUS;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  @Ref("form") form!: FormModel;
  mode: Mode = Mode.CREATE;
  longestDate = "";
  receiptArForm: FormValues = {
    invoiceType: "",
    customerBankName: "",
    branchWarehouseId: "",
    currency: "",
    receiptDate: moment(),
    receiptTime: moment(),
    customerId: "",
    customerBillToAddress: "",
    receiptMethod: "",
    chequeDate: "",
    chequeNumber: "",
    status: "New",
    receiptType: RECEIPT_TYPE.UNINDENTIFIED,
    receiptAmount: 0,
    currencyRate: 1,
    bankAccountId: "",
    description: "",
    fileAttachment: [],
  };
  currencyFrom = "IDR";
  currencyTo = "";
  customerName = "";
  customerId = "";
  titleModal:
    | "lbl_invoice_credit_memo"
    | "lbl_write_off_amount"
    | "lbl_additional_amount" = "lbl_invoice_credit_memo";
  formRules = {
    description: [
      {
        max: 1000,
      },
    ],
    branchWarehouseId: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    invoiceType: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    customerBankName: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    currency: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    receiptTime: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    receiptDate: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
      {
        validator: (_rule, value, callback) => {
          if (isDateAfterMaxDate(value)) {
            callback(
              this.$t(Messages.VALIDATION_MAX_DATE, {
                maxDate: moment().format(DEFAULT_DATE_FORMAT),
              })
            );
            return;
          }

          callback();
        },
        message: this.$t(Messages.VALIDATION_MAX_DATE, {
          maxDate: moment().format(DEFAULT_DATE_FORMAT),
        }),
      },
    ],
    receiptMethod: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    receiptAmount: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    chequeDate: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    chequeNumber: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    bankAccountId: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    customerBillToAddress: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    customerId: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    currencyRate: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
  };
  receiptMethodsOptions: OptionModel[] = [];
  bankAccountOption = {
    data: [] as DataResponseCompanyBank[],
    search: "",
    fetching: true,
  };
  customerAddressOption = {
    data: [],
    search: "",
    fetching: false,
  };
  dataInvoiceType: ResponseListMaster[] = [];
  columnsTable = [
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 300,
      scopedSlots: { customRender: "invoiceNumber" },
      responsiveColSelect: [
        {
          name: "invoiceNumber",
          style: "width: 100%;",
          disabled: false,
          value: "name",
          options: [] as any[],
          loading: false,
        },
      ],
      responsiveColInputNumber: [
        {
          name: "receiptAmount",
          placeholder: this.$t("lbl_receipt_amount"),
          style: "width: 100%",
          disabled: false,
        },
      ],
      responsiveButtonAdd: [
        "writeOfAmount",
        "invoiceCreditMemo",
        "additionalAmount",
      ], // column name in scopedslots
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_income_tax_amount_pph_amount"),
      dataIndex: "incomeTaxValue",
      key: "incomeTaxValue",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_paid_amount"),
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOfAmount",
      key: "writeOfAmount",
      width: 200,
      scopedSlots: { customRender: "writeOfAmount" },
    },
    {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      width: 200,
      scopedSlots: { customRender: "additionalAmount" },
    },
    {
      title: this.$t("lbl_invoice_outstanding"),
      dataIndex: "outstandingInvoice",
      key: "outstandingInvoice",
      width: 200,
      customRender: this.getOutstandingInvoice,
    },
    {
      title: this.$t("lbl_invoice_credit_memo"),
      dataIndex: "invoiceCreditMemo",
      key: "invoiceCreditMemo",
      width: 200,
      scopedSlots: { customRender: "invoiceCreditMemo" },
    },
    {
      title: this.$t("lbl_credit_amount"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_receipt_amount"),
      dataIndex: "receiptAmount",
      key: "receiptAmount",
      width: 200,
      scopedSlots: { customRender: "receiptAmount" },
    },
    {
      title: this.$t("lbl_action"),
      dataIndex: "operation",
      width: "120px",
      align: "center",
      scopedSlots: { customRender: "operation" },
      fixed: "right",
      button: ["delete"],
    },
  ];
  invoiceSource = [] as IReceiptLine[];
  tempInvoiceSource = [] as IReceiptLine[];
  disable = {
    form: false,
    addRowBtn: true,
    update: false,
    editableField: false,
  };
  showFields = {
    cheque: false,
    rate: false,
  };
  ALLOWED_FILE_SIZE = ALLOWED_FILE_SIZE;
  ALLOWED_FILE_TYPES = ALLOWED_IMAGE_FILE.concat(ALLOWED_EXCEL_FILE, [
    ".pdf",
  ]).join();
  show = {
    updateBtn: false,
  };
  id = "" as string;
  arReceiptDetail = {} as IArDataResponse;
  loadingIndicator = {
    data: false,
    submit: false,
    void: false,
    approved: false,
    updated: false,
    canceled: false,
    print: false,
  };
  headers = {
    authorization: "Bearer " + this.$store.state.access_token,
  };
  fileList = [] as any[];
  visible = false;
  keyActiveModal = 0;
  selectedRowKeysModal: number[] = [];
  dataSourceModal: CustomDataSourceModal[] = [];
  dataSourceModalWriteOfAndAdditional: LineAmountsAR[] = [];
  deleteLineId: string[] = [];
  columnsTableModal = [
    {
      title: this.$t("lbl_invoice_credit_memo"),
      dataIndex: "invoicePrepaymentNo",
      key: "invoicePrepaymentNo",
      width: 220,
      scopedSlots: { customRender: "invoicePrepaymentNumber" },
      responsiveColSelect: [
        {
          name: "invoicePrepaymentNumber",
          style: "width: 100%;",
          disabled: false,
          value: "name",
          options: [] as any[],
          loading: false,
        },
      ],
      responsiveColInput: [
        {
          name: "description",
          placeholder: this.$t("lbl_description"),
          style: "width: 100%",
          disabled: false,
        },
      ],
    },
    {
      title: this.$t("lbl_date_of_invoice_credit_memo"),
      dataIndex: "invoicePrepaymentDate",
      key: "invoicePrepaymentDate",
      width: 220,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "appliedAmount",
      key: "appliedAmount",
      width: 220,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 220,
      scopedSlots: { customRender: "description" },
    },
  ];
  columnsTableModalWriteOfAndAdditionalAmount = [
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      width: 220,
      scopedSlots: { customRender: "amount" },
      responsiveColSelect: [
        {
          name: "amountAccountId",
          style: "width: 100%;",
          disabled: false,
          value: "id",
          options: [] as any[],
          loading: false,
        },
      ],
      responsiveColInput: [
        {
          name: "amount",
          placeholder: this.$t("lbl_amount"),
          style: "width: 100%",
          disabled: false,
        },
      ],
    },
    {
      title: this.$t("lbl_account"),
      dataIndex: "amountAccountId",
      key: "amountAccountId",
      width: 220,
      scopedSlots: { customRender: "amountAccountId" },
    },
  ];
  currentTab = "Details";

  changeMenu(value) {
    this.currentTab = value;
  }

  get isUnidentified(): boolean {
    return this.arReceiptDetail.receiptType === "Unidentified";
  }

  created(): void {
    this.getReceiptMethod();
    this.getInvoiceType("");
    this.getListOfCoa("");
    const userPrivileges: IAuthorities[] =
      LocalStorageService.loadUserPrivilege();
    const receiptArPrivilege = userPrivileges.find(
      p => p.key === "invoice-receipt"
    );
    if (receiptArPrivilege) {
      if (receiptArPrivilege.privilege.update) {
        this.show.updateBtn = true;
      }
    }
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.disable.form = true;
      this.getArReceiptDetail();
    }
  }

  mounted() {
    this.mode = this.$route.meta.mode;
  }

  disabledDate(current) {
    // Can not select days before invoice date in table
    return current < moment(this.longestDate, DEFAULT_DATE_FORMAT);
  }

  setBaseCurrencyCreate() {
    const tempObject = {
      feature_base_currency: "",
    };
    this.$store.state.preferenceStore.dataPreference.forEach(dataForeach => {
      if (dataForeach.key === "feature_base_currency") {
        tempObject.feature_base_currency = dataForeach.value;
      }
    });
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (tempObject.feature_base_currency) {
      params.search = `secureId~${tempObject.feature_base_currency}`;
      settingsServices.listOfMasterCurrency(params, "").then(response => {
        this.currencyTo = response.data[0].currencyCode as string;
        if (this.mode && this.mode.toLocaleLowerCase() === Mode.CREATE) {
          this.receiptArForm.currency = response.data[0].currencyCode as string;
        }
      });
    }
  }

  handleCancel() {
    this.dataSourceModal = [];
    this.dataSourceModalWriteOfAndAdditional = [];
    this.deleteLineId = [];
    this.visible = false;
  }

  validationAmountAndAccount(): boolean {
    let tempError: string[] = [];
    this.dataSourceModalWriteOfAndAdditional.forEach(dataForeach => {
      // check 0
      if (dataForeach.amount === currencyFormat(0))
        tempError.push("Amount can't 0");
      // check empty
      if (dataForeach.amountAccountId === "")
        tempError.push("Account can't empty");
    });
    const valueAccount = this.dataSourceModalWriteOfAndAdditional.map(
      dataMap => dataMap.amountAccountId
    );
    // check duplicate account
    if (
      valueAccount.some(
        (dataSome, index) => valueAccount.indexOf(dataSome) !== index
      )
    )
      tempError.push("Account can't duplicate");
    if (tempError.length > 0) {
      tempError = [...new Set(tempError)];
      tempError.forEach(dataForeach => {
        this.$notification.error({
          message: "Error",
          description: dataForeach,
        });
      });
      return false;
    } else {
      return true;
    }
  }

  saveDataModal() {
    if (this.titleModal === "lbl_invoice_credit_memo") {
      this.invoiceSource[this.keyActiveModal].applyPrepayment.prepaymentLines =
        this.dataSourceModal.map(dataMap => {
          return {
            ...dataMap,
            appliedAmount: dataMap.appliedAmount as number,
          };
        });
      this.invoiceSource[this.keyActiveModal].invoiceCreditMemo =
        this.invoiceSource[
          this.keyActiveModal
        ].applyPrepayment.prepaymentLines[0]?.invoicePrepaymentNo;
      this.invoiceSource[
        this.keyActiveModal
      ].applyPrepayment.deletedPrepaymentLineIds = this.deleteLineId;

      const totalAmountDeleted =
        this.tempInvoiceSource.length > 0
          ? this.tempInvoiceSource[
              this.keyActiveModal
            ].applyPrepayment.prepaymentLines
              .filter(dataFilter =>
                this.invoiceSource[
                  this.keyActiveModal
                ].applyPrepayment.deletedPrepaymentLineIds.includes(
                  dataFilter.id as string
                )
              )
              .reduce((a, b) => {
                return a + changeCurrencytoNumeric(b.appliedAmount);
              }, 0)
          : 0;
      const totalAmount = this.dataSourceModal.reduce((a, b) => {
        return a + changeCurrencytoNumeric(b.appliedAmount);
      }, 0);
      // const countOutstanding = this.invoiceSource[this.keyActiveModal].tempInvoiceOutstanding as number - totalAmount
      // this.invoiceSource[this.keyActiveModal].invoiceOutstanding = countOutstanding
      this.invoiceSource[this.keyActiveModal].creditAmount =
        totalAmount - totalAmountDeleted;

      this.dataSourceModal = [];
      this.deleteLineId = [];
      this.visible = false;
    } else if (this.titleModal === "lbl_write_off_amount") {
      if (this.validationAmountAndAccount()) {
        // logic save to line amount
        // let tempDataWriteOfAmount = this.invoiceSource[this.keyActiveModal].lineAmounts.filter((dataFilter) => changeCurrencytoNumeric(dataFilter.writeOfAmount) > 0)
        const tempDataAdditionalAmount = this.invoiceSource[
          this.keyActiveModal
        ].lineAmounts.filter(
          dataFilter => changeCurrencytoNumeric(dataFilter.additionalAmount) > 0
        );
        const tempDataWriteOfAmount =
          this.dataSourceModalWriteOfAndAdditional.map(dataMap => {
            return {
              ...dataMap,
              writeOfAmount: dataMap.amount,
            };
          });
        this.invoiceSource[this.keyActiveModal].lineAmounts = [
          ...tempDataWriteOfAmount,
          ...tempDataAdditionalAmount,
        ];

        this.invoiceSource[this.keyActiveModal].writeOfAmount = currencyFormat(
          this.dataSourceModalWriteOfAndAdditional.reduce((a, b) => {
            return a + changeCurrencytoNumeric(b.amount);
          }, 0)
        );
        this.invoiceSource[this.keyActiveModal].deletedLineAmountIds =
          this.deleteLineId;
        this.dataSourceModalWriteOfAndAdditional = [];
        this.deleteLineId = [];
        this.visible = false;
      }
    } else if (this.titleModal === "lbl_additional_amount") {
      if (this.validationAmountAndAccount()) {
        // logic save to line amount
        const tempDataWriteOfAmount = this.invoiceSource[
          this.keyActiveModal
        ].lineAmounts.filter(
          dataFilter => changeCurrencytoNumeric(dataFilter.writeOfAmount) > 0
        );
        const tempDataAdditionalAmount =
          this.dataSourceModalWriteOfAndAdditional.map(dataMap => {
            return {
              ...dataMap,
              additionalAmount: dataMap.amount,
            };
          });
        this.invoiceSource[this.keyActiveModal].lineAmounts = [
          ...tempDataWriteOfAmount,
          ...tempDataAdditionalAmount,
        ];

        this.invoiceSource[this.keyActiveModal].additionalAmount =
          currencyFormat(
            this.dataSourceModalWriteOfAndAdditional.reduce((a, b) => {
              return a + changeCurrencytoNumeric(b.amount);
            }, 0)
          );
        this.invoiceSource[this.keyActiveModal].deletedLineAmountIds =
          this.deleteLineId;
        this.dataSourceModalWriteOfAndAdditional = [];
        this.deleteLineId = [];
        this.visible = false;
      }
    }

    this.invoiceSource = this.invoiceSource.slice();
  }

  handleReceiptMethodChange(value: string): void {
    if (value.toUpperCase() === RECEIPT_METHOD.CHEQUE.toUpperCase()) {
      this.showFields.cheque = true;
      this.formRules.chequeDate = [
        {
          required: true,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
      this.formRules.chequeNumber = [
        {
          required: true,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
    } else {
      this.showFields.cheque = false;
      this.formRules.chequeDate = [
        {
          required: false,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
      this.formRules.chequeNumber = [
        {
          required: false,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
    }
  }

  handleAddRow(): void {
    if (
      !this.receiptArForm.branchWarehouseId ||
      !this.receiptArForm.customerId ||
      !this.receiptArForm.currency
    ) {
      this.$notification.error({
        message: "Error",
        description: this.$t(
          "lbl_fill_branch_customer_typeinvoice_currency"
        ).toString(),
      });
      return;
    }
    this.getInvoiceAr();
    this.invoiceSource = [
      ...this.invoiceSource,
      {
        key: this.invoiceSource.length,
        invoiceARId: "",
        writeOfAmount: 0,
        createdDate: "",
        invoiceNumber: "",
        invoiceOutstanding: 0,
        invoiceDate: "",
        invoiceAmount: 0,
        incomeTaxValue: 0,
        paidAmount: 0,
        receiptAmount: 0,
        invoiceCreditMemo: "",
        idApplyPrepayment: "",
        applyPrepayment: {
          prepaymentLines: [],
          deletedPrepaymentLineIds: [],
        },
        lineAmounts: [],
        deletedLineAmountIds: [],
        additionalAmount: 0,
      },
    ];
  }

  handleAddRowModal() {
    if (this.titleModal === "lbl_invoice_credit_memo") {
      this.dataSourceModal = [
        ...this.dataSourceModal,
        {
          key:
            this.dataSourceModal.length > 0
              ? this.dataSourceModal[this.dataSourceModal.length - 1].key + 1
              : this.dataSourceModal.length,
          id: "",
          invoicePrepaymentId: "",
          appliedAmount: 0,
          description: "",
          invoicePrepaymentNo: "",
          invoicePrepaymentDate: "",
          createdDate: "",
          modifiedDate: "",
        },
      ];
    } else {
      this.dataSourceModalWriteOfAndAdditional = [
        ...this.dataSourceModalWriteOfAndAdditional,
        {
          writeOfAmount: currencyFormat(0),
          additionalAmount: currencyFormat(0),
          amountAccountId: "",
          amountAccount: "",
          key: this.dataSourceModalWriteOfAndAdditional.length,
          amount: currencyFormat(0),
          id: "",
        },
      ];
    }
  }

  filterAndDeleteRowModal(data) {
    if (this.selectedRowKeysModal.includes(data.key) && data.id)
      this.deleteLineId.push(data.id);
    return !this.selectedRowKeysModal.includes(data.key);
  }

  handleDeleteRowModal() {
    if (this.selectedRowKeysModal.length < 1) {
      this.$notification.error({
        message: "Error",
        description: "Select data first to delete",
      });
    } else {
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectedRowKeysModal.length,
        }),
        onOk: () => {
          if (this.titleModal === "lbl_invoice_credit_memo") {
            this.dataSourceModal = this.dataSourceModal.filter(data =>
              this.filterAndDeleteRowModal(data)
            );
            this.dataSourceModal.forEach((data, index) => (data.key = index));
            this.dataSourceModal = this.dataSourceModal.slice();
          } else {
            this.dataSourceModalWriteOfAndAdditional =
              this.dataSourceModalWriteOfAndAdditional.filter(data =>
                this.filterAndDeleteRowModal(data)
              );
            this.dataSourceModalWriteOfAndAdditional.forEach(
              (data, index) => (data.key = index)
            );
            this.dataSourceModalWriteOfAndAdditional =
              this.dataSourceModalWriteOfAndAdditional.slice();
          }
          this.selectedRowKeysModal = [];
        },
        onCancel() {
          return;
        },
      });
    }
  }

  handleSelectModal(value, key, col, _options): void {
    if (
      col === "invoicePrepaymentNumber" &&
      this.titleModal === "lbl_invoice_credit_memo"
    ) {
      const findColumnModal = this.columnsTableModal.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      if (value && findColumnModal) {
        // data invoice prepayment
        const findData = findColumnModal[0].options.find(
          dataFind => dataFind.invoiceNumber === value
        );
        this.dataSourceModal[key].appliedAmount = findData.amount;
        this.dataSourceModal[key].description = findData.description;
        this.dataSourceModal[key].invoicePrepaymentId = findData.id;
        this.dataSourceModal[key].invoicePrepaymentNo = findData.invoiceNumber;
        this.dataSourceModal[key].createdDate = findData.createdDate;
        this.dataSourceModal[key].modifiedDate = findData.modifiedDate;
        this.dataSourceModal[key].invoicePrepaymentDate = findData.invoiceDate;
      } else {
        this.dataSourceModal[key].appliedAmount = 0;
        this.dataSourceModal[key].description = "";
        this.dataSourceModal[key].invoicePrepaymentId = "";
        this.dataSourceModal[key].invoicePrepaymentNo = "";
        this.dataSourceModal[key].createdDate = "";
        this.dataSourceModal[key].modifiedDate = "";
        this.dataSourceModal[key].invoicePrepaymentDate = "";
        this.dataSourceModal[key].id = "";
      }
    } else if (
      this.titleModal === "lbl_write_off_amount" ||
      this.titleModal === "lbl_additional_amount"
    ) {
      const findColumnModal =
        this.columnsTableModalWriteOfAndAdditionalAmount.find(
          c => c.responsiveColSelect
        )?.responsiveColSelect;

      if (value && findColumnModal) {
        // data invoice prepayment
        const findData = findColumnModal[0].options.find(
          dataFind => dataFind.id === value
        );
        this.dataSourceModalWriteOfAndAdditional[key].amountAccountId = value;
        this.dataSourceModalWriteOfAndAdditional[key].amountAccount =
          findData.name;
      } else {
        this.dataSourceModalWriteOfAndAdditional[key].amountAccountId = "";
        this.dataSourceModalWriteOfAndAdditional[key].amountAccount = "";
      }
    }
    this.dataSourceModal = this.dataSourceModal.slice();
  }

  handleSearchSelectTableModal(value, _key, colName) {
    if (
      this.titleModal === "lbl_invoice_credit_memo" &&
      colName === "invoicePrepaymentNumber"
    ) {
      this.getListInvoicePrepayment(value);
    } else {
      this.getListOfCoa(value);
    }
  }

  handleInputModal(value, key, _objectColInput, objectColName, onEvent): void {
    if (this.titleModal === "lbl_invoice_credit_memo") {
      this.dataSourceModal[key][objectColName] = value;
      this.dataSourceModal = this.dataSourceModal.slice();
    } else {
      if (onEvent === "onFocus") {
        this.dataSourceModalWriteOfAndAdditional[key][objectColName] =
          changeCurrencytoNumeric(value);
      }
      if (onEvent === "onChange") {
        this.dataSourceModalWriteOfAndAdditional[key][objectColName] = value;
      }
      if (onEvent === "onBlur") {
        this.dataSourceModalWriteOfAndAdditional[key][objectColName] =
          currencyFormat(value || 0);
      }
    }
  }

  onSelectChange(value) {
    this.selectedRowKeysModal = value;
  }

  reponseDeleteRow(row): void {
    this.invoiceSource.splice(row.data.key, 1);
    this.invoiceSource.forEach((d, idx) => {
      d.key = idx;
    });
  }

  checkNumberAndReturnZero(data) {
    if (data) return data;
    else return 0;
  }

  handleSelect(value, key, col, options): void {
    if (value) {
      const selectedOptions = options.find(o => o.id === value);
      const selectedOptionsInvoiceNumber = options.find(o => o.name === value);
      const invoiceAmount =
        this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR
          ? selectedOptionsInvoiceNumber?.grandTotal
          : selectedOptionsInvoiceNumber?.amount || 0;
      switch (col) {
        case "invoiceNumber":
          if (selectedOptionsInvoiceNumber) {
            this.invoiceSource[key] = {
              ...this.invoiceSource[key],
              key,
              invoiceARId: selectedOptionsInvoiceNumber.id,
              receiptAmount: 0,
              writeOfAmount: 0,
              invoiceDate: selectedOptionsInvoiceNumber.invoiceDate,
              incomeTaxValue:
                selectedOptionsInvoiceNumber.invoiceIncomeTaxAmount,
              paidAmount: this.checkNumberAndReturnZero(
                selectedOptionsInvoiceNumber.paidAmount
              ),
              invoiceAmount: invoiceAmount,
              invoiceOutstanding: this.calculateOutstandingInvoice(
                this.checkNumberAndReturnZero(invoiceAmount),
                this.checkNumberAndReturnZero(
                  selectedOptionsInvoiceNumber.paidAmount
                ),
                this.checkNumberAndReturnZero(
                  changeCurrencytoNumeric(this.invoiceSource[key].writeOfAmount)
                ),
                this.checkNumberAndReturnZero(
                  this.invoiceSource[key].creditAmount
                ),
                this.checkNumberAndReturnZero(
                  this.invoiceSource[key].incomeTaxValue
                ),
                this.invoiceSource[key].receiptAmount || 0
              ),
              invoiceNumber: value,
            };
          }
          break;
        case "invoiceCreditMemo":
          if (selectedOptions) {
            this.invoiceSource[key] = {
              ...this.invoiceSource[key],
              invoiceCreditMemo: value,
              creditAmount: selectedOptions.amount,
            };
          }
          break;
      }
    } else {
      this.invoiceSource[key] = {
        ...this.invoiceSource[key],
        key,
        invoiceARId: "",
        receiptAmount: 0,
        writeOfAmount: 0,
        invoiceDate: "",
        paidAmount: 0,
        invoiceAmount: 0,
        invoiceOutstanding: 0,
        invoiceCreditMemo: "",
        invoiceNumber: "",
      };
    }
    this.invoiceSource = this.invoiceSource.slice();
  }

  handleInputNumber(value, key, _objectColInput, objectColName): void {
    this.invoiceSource[key][objectColName] = value;
  }

  handleInput(value, key, _objectColInput, objectColName, onEvent): void {
    switch (onEvent) {
      case "onFocus":
        this.invoiceSource[key][objectColName] = changeCurrencytoNumeric(value);
        break;
      case "onBlur":
        this.invoiceSource[key][objectColName] = currencyFormat(value);
        break;
      default:
        break;
    }
  }

  handleSearchSelectTable(value): void {
    this.searchInvoiceNumber(value);
  }

  searchInvoiceNumber(value: string): void {
    debounce(() => {
      this.getInvoiceAr(value);
    });
  }

  handleBack() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push("/accountreceivables/receiptar");
      },
      onCancel() {
        return;
      },
    });
  }

  handleAddData({ data, columnName }): void {
    switch (columnName) {
      case "invoiceCreditMemo":
        this.dataSourceModal = data.applyPrepayment.prepaymentLines;
        this.titleModal = "lbl_invoice_credit_memo";
        break;
      case "writeOfAmount":
        this.dataSourceModalWriteOfAndAdditional = data.lineAmounts.filter(
          dataFilter => changeCurrencytoNumeric(dataFilter.writeOfAmount) > 0
        );
        this.titleModal = "lbl_write_off_amount";
        break;
      case "additionalAmount":
        this.dataSourceModalWriteOfAndAdditional = data.lineAmounts.filter(
          dataFilter => changeCurrencytoNumeric(dataFilter.additionalAmount) > 0
        );
        this.titleModal = "lbl_additional_amount";
        break;
      default:
        break;
    }
    this.keyActiveModal = data.key;
    this.visible = true;
  }

  onSubmit(type: STATUS): void {
    this.form.validate(async valid => {
      if (!valid) {
        this.showNotifError("notif_empty_mandatory_fields");
        return;
      }
      if (!this.checkError()) {
        return;
      }

      try {
        const payload = this.prepareObject();
        let response = {} as IArDataResponse;

        switch (type) {
          case STATUS.VOID:
            this.loadingIndicator.void = true;
            response = await arService.voidArReceipt(payload, this.id);
            this.showNotifSuccess("notif_void_success", {
              documentNumber: response.documentNumber,
            });
            break;
          case STATUS.APPROVED:
            this.loadingIndicator.approved = true;
            response = await arService.approveArReceipt(payload, this.id);
            this.showNotifSuccess("notif_approve_success", {
              documentNumber: response.documentNumber,
            });
            break;
          case STATUS.UPDATED:
            if (this.disable.update) {
              this.disable.update = false;
              this.invoiceSource = this.invoiceSource.map(dataMap => {
                return {
                  ...dataMap,
                  disabledInput: false,
                  disabledSelect: false,
                };
              });
            } else if (!this.disable.update) {
              this.loadingIndicator.updated = true;
              response = await arService.updateArReceipt(payload, this.id);
              this.showNotifSuccess("notif_update_success", {
                documentNumber: response.documentNumber,
              });
            }
            break;
          case STATUS.CANCELED:
            this.loadingIndicator.canceled = true;
            response = await arService.cancelArReceipt(payload, this.id);
            this.showNotifSuccess("notif_cancel_success", {
              documentNumber: response.documentNumber,
            });
            break;
          default:
            break;
        }
        if (Object.keys(response).length > 0) {
          this.$router.push("/accountreceivables/receiptar");
        }
      } finally {
        this.loadingIndicator.void = false;
        this.loadingIndicator.approved = false;
        this.loadingIndicator.updated = false;
        this.loadingIndicator.canceled = false;
      }
    });
  }

  handleSubmit() {
    this.form.validate(async valid => {
      if (!valid) {
        this.showNotifError("notif_empty_mandatory_fields");
        return;
      }
      if (!this.checkError()) {
        return;
      }
      try {
        this.loadingIndicator.submit = true;
        const payload = this.prepareObject();
        let response = {} as IArDataResponse;
        if (this.id) {
          if (
            this.receiptArForm.receiptType === RECEIPT_TYPE.UNINDENTIFIED &&
            this.receiptArForm.status === STATUS.NEW
          ) {
            response = await arService.updateArReceipt(payload, this.id);
          } else if (
            this.arReceiptDetail.receiptType === RECEIPT_TYPE.UNINDENTIFIED &&
            this.receiptArForm.receiptType === RECEIPT_TYPE.APPLIED
          ) {
            response = await arService.applyArReceipt(payload, this.id);
          }
        } else {
          response = await arService.createArReceipt(payload);
        }
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response?.documentNumber,
        });
        this.$router.push("/accountreceivables/receiptar");
      } finally {
        this.loadingIndicator.submit = false;
      }
    });
  }

  returnDataByCondition(
    type: "incomeTaxValue" | "invoiceARId" | "invoicePrepaymentId",
    data
  ) {
    switch (type) {
      case "incomeTaxValue":
        return this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR
          ? data.incomeTaxValue
          : 0;
      case "invoiceARId":
        return this.receiptArForm.invoiceType === InvoiceType.INVOICE_AR
          ? data.invoiceARId
          : "";
      case "invoicePrepaymentId":
        return this.receiptArForm.invoiceType === InvoiceType.PREPAYMENT
          ? data.invoicePrepaymentId || data.invoiceARId
          : "";
      default:
        break;
    }
  }

  prepareObject(): IArDataPayload {
    const { getHour, getMinute, now, getSecond } = useDate();
    const data = {} as IArDataPayload;
    const excludeField = ["receiptTime"];
    for (const key in this.receiptArForm) {
      if (
        Object.prototype.hasOwnProperty.call(this.receiptArForm, key) &&
        !excludeField.includes(key)
      ) {
        data[key] = this.receiptArForm[key];
      }
    }

    // combine receipt time and receipt date
    data.receiptDate = moment(this.receiptArForm.receiptDate)
      .set({
        hour: getHour(this.receiptArForm.receiptTime),
        minute: getMinute(this.receiptArForm.receiptTime),
        second: getSecond(now()),
      })
      .format();

    if (this.receiptArForm.chequeDate) {
      data.chequeDate = moment(this.receiptArForm.chequeDate).format();
    }

    if (this.receiptArForm.currencyRate) {
      data.currencyRate = this.receiptArForm.currencyRate || 1;
    }

    data.receiptAmount = this.receiptArForm.receiptAmount || 0;

    if (data.receiptType === RECEIPT_TYPE.APPLIED) {
      data.status = STATUS.SUBMITTED;
    } else {
      data.status = STATUS.NEW;
    }

    data.description = this.receiptArForm.description;
    data.receiptLines = this.invoiceSource.map(s => {
      const receipt: IReceiptLine = {
        incomeTaxValue: this.returnDataByCondition("incomeTaxValue", s),
        invoiceARId: this.returnDataByCondition("invoiceARId", s),
        invoicePrepaymentId: this.returnDataByCondition(
          "invoicePrepaymentId",
          s
        ),
        receiptAmount: s.receiptAmount || 0,
        writeOfAmount: this.checkNumberAndReturnZero(
          changeCurrencytoNumeric(s.writeOfAmount)
        ),
        applyPrepayment: {
          ...s.applyPrepayment,
          prepaymentLines: s.applyPrepayment.prepaymentLines.map(dataMap => {
            return {
              ...dataMap,
              appliedAmount: changeCurrencytoNumeric(dataMap.appliedAmount),
            };
          }),
        },
        lineAmounts: s.lineAmounts.map(dataMap => {
          return {
            ...dataMap,
            writeOfAmount: changeCurrencytoNumeric(dataMap.writeOfAmount),
            additionalAmount: changeCurrencytoNumeric(dataMap.additionalAmount),
            amount: changeCurrencytoNumeric(dataMap.amount),
          };
        }),
        additionalAmount: this.checkNumberAndReturnZero(
          changeCurrencytoNumeric(s.additionalAmount)
        ),
        deletedLineAmountIds: s.deletedLineAmountIds,
      };

      if (this.id) {
        receipt.id = s.id;
      }
      return receipt;
    });
    data.fileAttachment = this.fileList.map(d => d.url);
    return data;
  }

  get total() {
    return {
      additionalAmount: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(prev || 0)
          .plus(changeCurrencytoNumeric(curr.additionalAmount || 0))
          .toNumber();
      }, 0),
      invoice: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(prev || 0).plus(curr.invoiceAmount || 0).toNumber();
      }, 0),
      paid: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(prev || 0)
          .plus(curr.paidAmount || 0)
          .plus(changeCurrencytoNumeric(curr.receiptAmount))
          .toNumber();
      }, 0),
      writeOff: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(prev || 0)
          .plus(changeCurrencytoNumeric(curr.writeOfAmount) || 0)
          .toNumber();
      }, 0),
      outstandingInvoice: this.invoiceSource.reduce((prev, curr) => {
        return new Decimal(prev || 0)
          .plus(curr.invoiceAmount || 0)
          .minus(curr.incomeTaxValue || 0)
          .minus(curr.paidAmount || 0)
          .minus(changeCurrencytoNumeric(curr.writeOfAmount) || 0)
          .minus(changeCurrencytoNumeric(curr.receiptAmount))
          .minus(changeCurrencytoNumeric(curr.creditAmount || 0))
          .toNumber();
      }, 0),
    };
  }

  get statusDocument() {
    return {
      new: this.receiptArForm.status === STATUS.NEW,
      submitted: this.receiptArForm.status === STATUS.SUBMITTED,
      posted: this.receiptArForm.status === STATUS.POSTED,
      void: this.receiptArForm.status === STATUS.VOID,
      approved: this.receiptArForm.status === STATUS.APPROVED,
      updated: this.receiptArForm.status === STATUS.UPDATED,
      canceled: this.receiptArForm.status === STATUS.CANCELED,
    };
  }

  get allowApply(): boolean {
    const status: boolean = STATUS.NEW === this.receiptArForm.status;
    const isEdit = !!this.id;

    return !this.disable.editableField && isEdit && status;
  }

  get isCanVoid(): boolean {
    const status: boolean = this.receiptArForm.status === STATUS.APPROVED;
    const isEdit = !!this.id;
    const type =
      this.receiptArForm.receiptType === RECEIPT_TYPE.APPLIED ||
      this.receiptArForm.receiptType === RECEIPT_TYPE.UNINDENTIFIED;
    return isEdit && status && type;
  }

  handleChangeCustomer(value: string): void {
    this.invoiceSource = this.invoiceSource.map(dataMap => {
      return {
        ...dataMap,
        applyPrepayment: {
          ...dataMap.applyPrepayment,
          prepaymentLines: [],
          deletedPrepaymentLineIds: [],
        },
      };
    });
    this.dataSourceModal = [];
    if (value) {
      this.getInvoiceAr();
      this.getListInvoicePrepayment("");
      this.disable.addRowBtn = false;
      this.receiptArForm.receiptType = RECEIPT_TYPE.APPLIED;
      this.formRules.customerBillToAddress = [
        {
          required: true,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
    } else {
      this.disable.addRowBtn = true;
      this.receiptArForm.receiptType = RECEIPT_TYPE.UNINDENTIFIED;
      this.formRules.customerBillToAddress = [
        {
          required: false,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
      this.invoiceSource = [];
    }
  }
  initCustomerAddress(value: ContactData): void {
    this.customerAddressOption.fetching = true;
    this.customerAddressOption.data = value?.addressDataList
      .filter(dataFilter => dataFilter.billTo)
      .map(a => a.address)
      .filter(
        (valueFilter, index, self) =>
          index === self.findIndex(t => t === valueFilter)
      );
    this.customerAddressOption.fetching = false;
  }

  handleCurrencyChange(value: string): void {
    this.currencyFrom = value;
    if (value === "IDR") {
      this.showFields.rate = false;
      this.receiptArForm.currencyRate = 1;
      this.formRules.currencyRate = [
        {
          required: false,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
    } else {
      this.showFields.rate = true;
      this.formRules.currencyRate = [
        {
          required: true,
          message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
        },
      ];
    }
    this.getInvoiceAr();
  }

  formattingCurrency(event): string {
    event.preventDefault();
    return currencyFormat(event.target.value);
  }

  changeCurrencyToNumeric(event): number {
    event.preventDefault();
    return changeCurrencytoNumeric(event.target.value);
  }

  searchBankAccount(value: string): void {
    debounce(() => {
      this.bankAccountOption.search = value;
      this.getBankList();
    });
  }

  pushColumnTable() {
    this.columnsTable.splice(2, 0, {
      title: this.$t("lbl_income_tax_amount_pph_amount"),
      dataIndex: "incomeTaxValue",
      key: "incomeTaxValue",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    });
    this.columnsTable.splice(5, 0, {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOfAmount",
      key: "writeOfAmount",
      width: 200,
      scopedSlots: { customRender: "writeOfAmount" },
    });
    this.columnsTable.splice(6, 0, {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      width: 200,
      scopedSlots: { customRender: "additionalAmount" },
    });
    this.columnsTable.splice(8, 0, {
      title: this.$t("lbl_invoice_credit_memo"),
      dataIndex: "invoiceCreditMemo",
      key: "invoiceCreditMemo",
      width: 200,
      scopedSlots: { customRender: "invoiceCreditMemo" },
    });
    this.columnsTable.splice(9, 0, {
      title: this.$t("lbl_credit_amount"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: 200,
      scopedSlots: { customRender: "creditAmount" },
    });
  }

  handleChangeInvoiceType() {
    const incomeTaxValue = this.columnsTable.findIndex(
      c => c.dataIndex === "incomeTaxValue"
    );
    const writeOfAmount = this.columnsTable.findIndex(
      c => c.dataIndex === "writeOfAmount"
    );
    const additionalAmount = this.columnsTable.findIndex(
      c => c.dataIndex === "additionalAmount"
    );
    const actionColumn = this.columnsTable.findIndex(
      c => c.dataIndex === "invoiceCreditMemo"
    );
    const actionCreditAmount = this.columnsTable.findIndex(
      c => c.dataIndex === "creditAmount"
    );
    switch (this.receiptArForm.invoiceType) {
      case InvoiceType.INVOICE_AR:
        if (
          actionColumn === -1 &&
          actionCreditAmount === -1 &&
          incomeTaxValue === -1 &&
          writeOfAmount === -1 &&
          additionalAmount === -1
        ) {
          this.pushColumnTable();
        }
        break;
      case InvoiceType.PREPAYMENT:
        this.columnsTable.splice(incomeTaxValue, 1);
        this.columnsTable.splice(writeOfAmount - 1, 1);
        this.columnsTable.splice(additionalAmount - 2, 1);
        this.columnsTable.splice(actionColumn - 3, 1);
        this.columnsTable.splice(actionCreditAmount - 4, 1);
        break;
      default:
        break;
    }
    this.getInvoiceAr();
  }

  getInvoiceType(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
      name: "INVOICE_AR_TYPE",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    masterServices.listMaster(params).then(response => {
      this.dataInvoiceType = response.filter(
        dataFilter => dataFilter.value !== "Credit Memo"
      );
    });
  }

  getBankList(): void {
    this.bankAccountOption.fetching = true;
    const param: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sorts: `createdDate:desc`,
      search: `currency.currencyCode~*${this.currencyFrom}*`,
    };
    if (this.bankAccountOption.search) {
      param.search = `bankAccName~*${this.bankAccountOption.search}*_OR_bankAccNumber~*${this.bankAccountOption.search}*_OR_bankName~*${this.bankAccountOption.search}*_AND_currency.currencyCode~*${this.currencyFrom}*`;
    }
    masterServices
      .listCompanyBank(param)
      .then(res => {
        this.bankAccountOption.data = res.data;
      })
      .finally(() => (this.bankAccountOption.fetching = false));
  }

  getReceiptMethod(): void {
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "INVOICE_RECEIPT_METHOD",
    };
    masterServices.listMaster(param).then(res => {
      this.receiptMethodsOptions = res.map(r => {
        return {
          label: r.value,
          value: r.value,
        };
      });
    });
  }

  checkIsUndefinedAndReturnString(data) {
    if (data) return data;
    else return "";
  }

  filterDuplicateWithSameId(dataArray) {
    return dataArray.filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id)
    );
  }

  assignBankAccountName(response) {
    if (response.bankAccountName) {
      const partial = (
        bankAccountOption: DataResponseCompanyBank[],
        fieldsUpdate: Partial<DataResponseCompanyBank>
      ) => {
        return [
          ...bankAccountOption,
          fieldsUpdate,
        ] as DataResponseCompanyBank[];
      };
      this.bankAccountOption.data = this.filterDuplicateWithSameId(
        partial(this.bankAccountOption.data, {
          id: response.bankAccountId,
          bankName: response.bankAccountName.split(" - ")[0],
          bankAccNumber: response.bankAccountName.split(" - ")[1],
        })
      );
    }
  }

  mappingPrepaymentLines(dataMapLine, index) {
    return {
      ...dataMapLine,
      key: index,
      invoicePrepaymentDate: moment(dataMapLine.invoicePrepaymentDate).format(
        DEFAULT_DATE_FORMAT
      ),
      disabledSelect: true,
      disabledInput: true,
    };
  }

  async getArReceiptDetail() {
    try {
      this.loadingIndicator.data = true;
      this.disable.update = true;
      const res = await arService.getDetailArReceipt(this.id);
      this.assignBankAccountName(res);
      if (res.currency !== "IDR") {
        this.currencyFrom = res.currency;
        this.showFields.rate = true;
        this.formRules.currencyRate = [
          {
            required: true,
            message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
          },
        ];
      }
      if (
        res.receiptMethod.toUpperCase() === RECEIPT_METHOD.CHEQUE.toUpperCase()
      )
        this.showFields.cheque = true;

      // Assign form value
      this.arReceiptDetail = res;
      const findColumn = this.columnsTableModal.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      res.receiptLines.forEach(item => {
        item.lineAmounts.forEach(dataAmount => {
          this.pushMissingCoaAdditionalAmount(dataAmount.amountAccountId);
        });

        // assign options invoice credit memo
        const temp = item.applyPrepayment.prepaymentLines.map(dataMap => {
          return {
            ...dataMap,
            name: dataMap.invoicePrepaymentNo,
            id: this.checkIsUndefinedAndReturnString(
              dataMap.invoicePrepaymentId
            ),
            remainingAmount: dataMap.appliedAmount,
            invoicePrepaymentDate: moment(dataMap.invoicePrepaymentDate).format(
              DEFAULT_DATE_FORMAT
            ),
            amount: dataMap.appliedAmount,
            invoiceNumber: dataMap.invoicePrepaymentNo,
            invoiceDate: moment(dataMap.invoicePrepaymentDate).format(
              DEFAULT_DATE_FORMAT
            ),
          };
        });
        if (findColumn) {
          findColumn[0].options.push(...temp);
          // cek duplicate
          findColumn[0].options = findColumn[0].options.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.id === value.id)
          );
        }
      });

      for (const key in this.receiptArForm) {
        if (Object.prototype.hasOwnProperty.call(this.receiptArForm, key)) {
          this.receiptArForm[key] = res[key];
        }
      }
      this.receiptArForm.receiptDate = moment(res.receiptDate);
      this.receiptArForm.receiptTime = moment(res.receiptDate);
      if (res.chequeDate) {
        this.receiptArForm.chequeDate = moment(res.chequeDate);
      }
      this.receiptArForm.receiptAmount = res.receiptAmount;
      this.receiptArForm.currencyRate = res.currencyRate;
      this.customerName = res.customerName;
      this.customerId = res.customerId;

      // Assign invoice list
      res.receiptLines.forEach((r, idx) => {
        r.key = idx;
        r.writeOfAmount = currencyFormat(r.writeOfAmount);
      });
      this.invoiceSource = res.receiptLines.map(dataMap => {
        return {
          ...dataMap,
          creditAmount: dataMap.applyPrepayment.prepaymentLines.reduce(
            (a, b) => new Decimal(b.appliedAmount || 0).plus(a).toNumber(),
            0
          ),
          applyPrepayment: {
            ...dataMap.applyPrepayment,
            prepaymentLines: dataMap.applyPrepayment.prepaymentLines.map(
              (dataMapLine, index) =>
                this.mappingPrepaymentLines(dataMapLine, index)
            ),
          },
          invoiceCreditMemo: this.checkIsUndefinedAndReturnString(
            dataMap.applyPrepayment?.prepaymentLines[0]?.invoicePrepaymentNo
          ),
          idApplyPrepayment: this.checkIsUndefinedAndReturnString(
            dataMap.applyPrepayment?.prepaymentLines[0]?.id
          ),
          invoicePrepaymentId: this.checkIsUndefinedAndReturnString(
            dataMap.invoicePrepaymentId
          ),
          lineAmounts: dataMap.lineAmounts.map(dataMapLineAmount => {
            return {
              ...dataMapLineAmount,
              writeOfAmount: currencyFormat(dataMapLineAmount.writeOfAmount),
              additionalAmount: currencyFormat(
                dataMapLineAmount.additionalAmount
              ),
              amount:
                changeCurrencytoNumeric(dataMapLineAmount.writeOfAmount) === 0
                  ? currencyFormat(dataMapLineAmount.additionalAmount)
                  : currencyFormat(dataMapLineAmount.writeOfAmount),
            };
          }),
          additionalAmount: currencyFormat(dataMap.additionalAmount),
        };
      });
      this.tempInvoiceSource = res.receiptLines.map(dataMap => {
        return {
          ...dataMap,
          applyPrepayment: {
            ...dataMap.applyPrepayment,
            prepaymentLines: dataMap.applyPrepayment.prepaymentLines.map(
              (dataMapLine, index) =>
                this.mappingPrepaymentLines(dataMapLine, index)
            ),
          },
          invoiceCreditMemo: this.checkIsUndefinedAndReturnString(
            dataMap.applyPrepayment?.prepaymentLines[0]?.invoicePrepaymentNo
          ),
          idApplyPrepayment: this.checkIsUndefinedAndReturnString(
            dataMap.applyPrepayment?.prepaymentLines[0]?.id
          ),
          invoicePrepaymentId: this.checkIsUndefinedAndReturnString(
            dataMap.invoicePrepaymentId
          ),
        };
      });

      // Assign journal list
      this.arReceiptDetail.journals = [
        {
          number: res.journalNo,
          id: res.journalId,
        },
      ];

      const response = await this.getListInvoicePrepaymentForViewAndUpdate("");
      if (findColumn) {
        const temp = response.data.map((dataMap, index) => {
          return this.mappingInvoice(dataMap, index);
        });
        findColumn[0].options.push(...temp);
        findColumn[0].options = findColumn[0].options.filter(
          (value, index, self) =>
            index === self.findIndex(t => t.id === value.id)
        );
      }

      this.disable.form = true;
      this.setEditableFields();
      this.handleChangeInvoiceType();
    } finally {
      this.loadingIndicator.data = false;
    }
  }

  setEditableFields() {
    if (this.arReceiptDetail.customerId) {
      this.disable.addRowBtn = false;
    } else {
      this.disable.addRowBtn = true;
    }

    if (
      this.arReceiptDetail.receiptType.toUpperCase() ===
      RECEIPT_TYPE.APPLIED.toUpperCase()
    ) {
      this.disable.editableField = true;
      const actionColumn = this.columnsTable.findIndex(
        c => c.dataIndex === "operation"
      );
      if (actionColumn >= 0) {
        this.columnsTable.splice(actionColumn, 1);
      }
      this.invoiceSource.forEach(r => {
        r.disabledSelect = true;
        r.disabledInput = true;
      });
    } else {
      this.disable.editableField = false;
    }
  }

  getListOfCoa(value) {
    const params = {
      page: 0,
      limit: 10,
      search: `isParent~false`,
    } as RequestQueryParamsModel;
    const findColumn = this.columnsTableModalWriteOfAndAdditionalAmount.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    if (value)
      params.search =
        `code~*${value}*_OR_description~*${value}*_AND_` + params.search;

    if (findColumn) {
      findColumn[0].loading = true;
    }
    // list coa
    settingsServices
      .listOfCoa(params, "")
      .then(data => {
        if (findColumn) {
          findColumn[0].options = data.data.map(dataMap => {
            return {
              ...dataMap,
              name: `${dataMap.code}-${dataMap.description}`,
            };
          });
        }
      })
      .finally(() => {
        if (findColumn) {
          findColumn[0].loading = false;
        }
      });
  }

  pushMissingCoaAdditionalAmount(id: string) {
    const findColumn = this.columnsTableModalWriteOfAndAdditionalAmount.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    settingsServices
      .listOfCoa({ search: "secureId~" + id }, "")
      .then(response => {
        if (findColumn) {
          console.log("column", findColumn);
          const [opt] = response.data.map(dataMap => {
            return {
              ...dataMap,
              name: `${dataMap.code}-${dataMap.description}`,
            };
          });
          findColumn[0].options.push(opt);
          removeDuplicate(findColumn[0].options);
        }
      });
  }

  getInvoicePrepayment(docNumber?: string) {
    const findColumn = this.columnsTable.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    if (findColumn) {
      findColumn[0].loading = true;
      const param: RequestQueryParamsModel = {
        page: 0,
        limit: 20,
        search: `branchWarehouse.secureId~*${this.receiptArForm.branchWarehouseId}*_AND_customer.secureId~*${this.receiptArForm.customerId}*_AND_priceCurrency.currencyCode~*${this.receiptArForm.currency}*_AND_invoicePrepaymentStatus~Unpaid`,
      };
      if (docNumber) {
        param.search += `_AND_documentNumber~*${docNumber}*`;
      }
      invoicePrepayment
        .getListInvoicePrepayment(param)
        .then(res => {
          // Add new property [name] to render invoice number in table
          res.data.forEach(d => {
            d.name = d.invoiceNumber;
          });
          if (this.receiptArForm.invoiceType === InvoiceType.PREPAYMENT)
            findColumn[0].options = res.data;
          // findColumn[1].options = res.data
        })
        .finally(() => (findColumn[0].loading = false));
    }
  }

  getInvoiceArList(docNumber?: string) {
    const findColumn = this.columnsTable.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    if (findColumn) {
      findColumn[0].loading = true;
      const param: RequestQueryParamsModel = {
        page: 0,
        limit: 20,
        search: `status~*Partial Paid*_OR_status~Unpaid_AND_branchWarehouse.secureId~*${this.receiptArForm.branchWarehouseId}*_AND_customer.secureId~*${this.receiptArForm.customerId}*_AND_priceCurrency.currencyCode~*${this.receiptArForm.currency}*`,
      };
      if (docNumber) {
        param.search += `_AND_documentNumber~*${docNumber}*`;
      }
      invoiceServices
        .getListInvoiceAR(param)
        .then(res => {
          // Add new property [name] to render invoice number in table
          res.data.forEach(d => {
            d.name = d.documentNumber;
          });
          findColumn[0].options = res.data;
        })
        .finally(() => (findColumn[0].loading = false));
    }
  }

  mappingInvoice(dataMap, index) {
    return {
      ...dataMap,
      invoiceDate: moment(dataMap.invoiceDate).format(DEFAULT_DATE_FORMAT),
      amount: currencyFormat(dataMap.amount),
      name: dataMap.invoiceNumber,
      key: index,
    };
  }

  getListInvoicePrepayment(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
      search: `branchWarehouse.secureId~${this.receiptArForm.branchWarehouseId}_AND_customer.secureId~*${this.receiptArForm.customerId}*_AND_invoiceType~*Credit Memo*`,
    };
    if (valueSearch) params.search += `_AND_documentNumber~*${valueSearch}*`;
    invoicePrepayment.getListInvoicePrepayment(params).then(response => {
      const findColumnModal = this.columnsTableModal.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      if (findColumnModal) {
        findColumnModal[0].options = response.data.map((dataMap, index) => {
          return this.mappingInvoice(dataMap, index);
        });
      }
    });
  }

  getListInvoicePrepaymentForViewAndUpdate(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
      search: `branchWarehouse.secureId~${this.receiptArForm.branchWarehouseId}_AND_customer.secureId~*${this.receiptArForm.customerId}*_AND_invoiceType~*Credit Memo*`,
    };
    if (valueSearch) params.search += `_AND_documentNumber~*${valueSearch}*`;
    return invoicePrepayment.getListInvoicePrepayment(params);
  }

  getInvoiceAr(docNumber?: string): void {
    switch (this.receiptArForm.invoiceType) {
      case InvoiceType.INVOICE_AR:
        this.getInvoiceArList(docNumber);
        break;
      case InvoiceType.PREPAYMENT:
        this.getInvoicePrepayment(docNumber);
        break;
      default:
        break;
    }
  }

  getOutstandingInvoice(value: unknown, row: IReceiptLine): unknown {
    return {
      children: currencyFormat(
        this.calculateOutstandingInvoice(
          row.invoiceAmount || 0,
          row.paidAmount || 0,
          changeCurrencytoNumeric(row.writeOfAmount),
          row.creditAmount || 0,
          row.incomeTaxValue || 0,
          changeCurrencytoNumeric(row.receiptAmount) || 0
        )
      ),
    };
  }

  calculateOutstandingInvoice(
    invoiceAmount: number,
    paidAmount: number,
    writeOffAmount: number,
    creditAmount: number,
    incomeTaxValue: number,
    receiptAmount: number
  ): number {
    let outstandingAmount =
      invoiceAmount -
      paidAmount -
      writeOffAmount -
      creditAmount -
      incomeTaxValue -
      receiptAmount;
    if (outstandingAmount < 0) {
      outstandingAmount = 0;
    }
    return outstandingAmount;
  }

  handleApply(): void {
    this.formRules.customerId = [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ];
    this.formRules.customerBillToAddress = [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ];
    this.handleSubmit();
  }

  beforeUpload(file) {
    const isLt5M = file.size <= ALLOWED_FILE_SIZE;
    if (!isLt5M) {
      this.$message.error(
        this.$t("lbl_message_file_size_exceeded", {
          fileSize: `${ALLOWED_FILE_SIZE / 1000000}MB`,
        }).toString()
      );
    }
    return isLt5M;
  }

  handleAttachmentChange(info) {
    let fileList = [...info.fileList];

    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.fileList = fileList;
  }

  removeFile(file) {
    this.fileList = this.fileList.filter(f => f.url !== file.url);
  }

  clickJournal(id) {
    if (id) this.$router.push("/generaljournal/journal/detail/" + id);
  }

  checkError(): boolean {
    // Check whether invoiceSource is empty when receiptType is Applied, if yes it will throw an error
    if (
      this.invoiceSource.length <= 0 &&
      this.receiptArForm.receiptType === RECEIPT_TYPE.APPLIED
    ) {
      this.showNotifError("notif_empty_invoice_source");
      return false;
    }

    // Check whether receipt date of invoiceSource is before invoice date that user inputs in header form
    const invalidateInvoice = this.invoiceSource.find(d =>
      moment(this.receiptArForm.receiptDate).isBefore(
        moment(d.invoiceDate),
        "days"
      )
    );
    if (invalidateInvoice) {
      this.showNotifError("notif_invalidate_invoice_source", {
        invoiceNo: invalidateInvoice.invoiceNumber,
      });
      return false;
    }

    const totalReceiptAmount = this.invoiceSource.reduce(
      (prev, curr) =>
        new Decimal(prev || 0)
          .plus(changeCurrencytoNumeric(curr.receiptAmount || 0))
          .toNumber(),
      0
    );

    // Check total receipt amount in invoice list < receipt amount in main form
    if (totalReceiptAmount > this.receiptArForm.receiptAmount) {
      this.showNotifError("lbl_notif_overbilled_receipt");
      return false;
    }
    return true;
  }

  handlePrint(): void {
    this.loadingIndicator.print = true;
    arService
      .printArReceipt(this.id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        printJs(url);
      })
      .finally(() => (this.loadingIndicator.print = false));
  }
}
